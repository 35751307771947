import './../assets/css/style.css';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css' ;
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ImgBanner } from "./banner";
import { BoxTitle } from "./box";
import { Section } from "./section";
import { TitleMarca } from "./Text";

import BannerDesk from "./../img/banners-bottom/banner-bottom-desk-cyber-2024.jpeg";
import BannerMob from "./../img/banners-bottom/banner-bottom-mob-cyber-2024.jpeg";
import BannerBottom1 from './../img/banners-bottom/linha-casual-cyber.jpeg';
import BannerBottom2 from './../img/banners-bottom/camisas-oficiais-cyber.jpeg';
import BannerBottom3 from './../img/banners-bottom/chuteiras-cyber.jpeg';
import BannerBottom4 from './../img/banners-bottom/tenis-para-corrida-cyber.jpeg';

function BannerBottomCyber() {
    const bannersbottom = {
        dots: true,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: true,
        responsive: [
          { breakpoint: 600,  settings: { slidesToShow: 1, slidesToScroll: 1 } },
          { breakpoint: 767,  settings: { slidesToShow: 2, slidesToScroll: 1 } },
          { breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 1 } }
        ],
    }
    /* const BannerFull = 'https://blackfriday.futfanatics.com.br/img/banner/bannerscyber/banner-middle-desk.png';
    const BannerFullMob = 'https://blackfriday.futfanatics.com.br/img/banner/bannerscyber/banner-middle_mobile.png';
    const BannerBottom1 = 'https://blackfriday.futfanatics.com.br/img/banner/bannerscyber/trio-bottom_cyber.png';
    const BannerBottom2 = 'https://blackfriday.futfanatics.com.br/img/banner/bannerscyber/trio-bottom_cyber2.png';
    const BannerBottom3 = 'https://blackfriday.futfanatics.com.br/img/banner/bannerscyber/trio-bottom_cyber3.png'; */

        return(
            <>
                <div className='box-banner-full'>
                    <a href='https://www.futfanatics.com.br/esportes/automobilismo/?order=4'>
                        <img className='banner-full d-md-block d-none' src={BannerDesk}/>
                        <img className='banner-full d-md-none' src={BannerMob}/>   
                    </a>
                    
                </div>
                <div className='container mt-3'>
                    <Slider {...bannersbottom} className='slick-banners_bottom'>
                        <div className='item'>
                            <a href='https://www.futfanatics.com.br/casual'>
                                <img className='bannersBottom' src={BannerBottom1}/>
                            </a>
                        </div>
                        <div className='item'>
                            <a href='https://www.futfanatics.com.br/loja/busca.php?loja=311840&variacao=camisa-oficial_sim'>
                                <img className='bannersBottom' src={BannerBottom2}/>
                            </a>
                        </div>
                        <div className='item'>
                            <a href='https://www.futfanatics.com.br/futebol/chuteiras?order=1'>
                                <img className='bannersBottom' src={BannerBottom3}/>
                            </a>
                        </div>
                        <div className='item'>
                            <a href='https://www.futfanatics.com.br/treino/calcados/corrida'>
                                <img className='bannersBottom' src={BannerBottom4}/>
                            </a>
                        </div>
                    </Slider>
                </div>
          
            </>
        )
}

export default BannerBottomCyber;