import "./../assets/css/style.css";
import React, { Component } from "react";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BottomTitle } from "./Text";
import { Section } from "./section";
import { BoxForms } from "./box";
import { StyledButton } from "./Button";

class Forms extends Component {
	componentDidMount() {
        $('.newsletter_form').on('submit', function(event) {
            event.preventDefault();
    
            var form = $(this);
            var formData = form.serialize();
            var url = 'https://microservicos.futfanatics.com.br/api/v1/futfanatics-nacional/dinamizeAjax?url=' + encodeURIComponent(form.attr('action'));
    
            form.find('.msg-resp').html('').removeClass('text-success text-danger text-info').slideUp();
    
            if (!form.find('select').val()) {
                form.find('.msg-resp').html('Escolha o seu time.').addClass('text-info').slideDown();
                return false;
            }
            
            $.post(url, formData, function(response) {
                if (response.status) {
                    form.find('.msg-resp').html('Boa jogada, e-mail cadastrado com sucesso!').addClass('text-success').slideDown();
                    form.find('.form-control').addClass('sucess');
    
                } else {
                    form.find('.msg-resp').html('Desculpe-nos, ocorreu um erro ao cadastrar.').addClass('text-danger').slideDown();
                    form.find('.form-control').addClass('error')
                    console.log('Error form dinamize: ' + response.error_msg.result);
                }
            });
    
            return false;
        });

        const today = new Date();
  const targetDate = new Date('2024-12-01T00:00:00');

  if (today < targetDate) {
    $('.btn-envio').removeClass('cyber');
  } else {
    $('.btn-envio').addClass('cyber');
  }
};   
  render() {
    return (
      <section className="c-forms">
        <div className="container">
          <div className="row justify-content-center">
            <BoxForms className="col-md-12 col-12">
              <BottomTitle>
                Inscreva-se e fique por dentro das novidades!
              </BottomTitle>
              <form
                class="newsletter_form justify-content-center"
                action="https://receiver.emkt.dinamize.com/in/301603/1/73cfd/0"
                method="post"
              >
                <div class="row justify-content-center align-items-center mt-4">
                  <input type="hidden" name="update_mode" value="AS" />
                  <input type="hidden" name="form-code" value="120" />
                  <input type="hidden" name="isMsg" value="true" />
                  <input
                    type="hidden"
                    name="text-confirmation"
                    value="U2V1IGUtbWFpbCBmb2kgY2FkYXN0cmFkbyBjb20gc3VjZXNzbyE="
                  />
                  <input type="hidden" name="text-error" value="" />
                  <input type="hidden" name="text-alert" value="" />
                  <input
                    type="hidden"
                    name="cmp4"
                    value="Landing Page Black Friday"
                  />
                  <input type="hidden" name="ignore-fields" value="on" />
                  <input type="hidden" name="phase-change" value="off" />

                  <div class="forms-input__text col-12 col-md-3">
                    <input
                      type="email"
                      name="cmp1"
                      id="cmp1"
                      class="type_EMAIL field-required"
                      placeholder="E-mail"
                    />
                  </div>
                  <div class="forms-input__text col-12 col-md-3">
                    <select
                      class="invi-select field-required type_LVM"
                      name="cmp12"
                    >
                      <option selected>Para qual time brasileiro você torce?</option>
                      <option value="Todos os Clubes da Lista">
                        Todos os Clubes da Lista
                      </option>
                      <option value="Outro">Outro</option>
                      <option value="Nenhum">Nenhum</option>
                      <option value="ABC de Natal">ABC de Natal</option>
                      <option value="Aimoré">Aimoré</option>
                      <option value="Altos do Piauí">Altos do Piauí</option>
                      <option value="América Mineiro">América Mineiro</option>
                      <option value="América RJ">América RJ</option>
                      <option value="América RN">América RN</option>
                      <option value="ASA de Arapiraca">ASA de Arapiraca</option>
                      <option value="Athletico Paranaense">
                        Athletico Paranaense
                      </option>
                      <option value="Atlético Goianiense">
                        Atlético Goianiense
                      </option>
                      <option value="Atlético Mineiro">Atlético Mineiro</option>
                      <option value="Audax Osasco">Audax Osasco</option>
                      <option value="Avaí">Avaí</option>
                      <option value="Bahia">Bahia</option>
                      <option value="Bahia de Feira">Bahia de Feira</option>
                      <option value="Bangu">Bangu</option>
                      <option value="Boa Esporte">Boa Esporte</option>
                      <option value="Botafogo">Botafogo</option>
                      <option value="Botafogo da Paraíba">
                        Botafogo da Paraíba
                      </option>
                      <option value="Botafogo de Ribeirão Preto">
                        Botafogo de Ribeirão Preto
                      </option>
                      <option value="Bragantino">Bragantino</option>
                      <option value="Brasil de Pelotas">
                        Brasil de Pelotas
                      </option>
                      <option value="Brasília">Brasília</option>
                      <option value="Brasiliense">Brasiliense</option>
                      <option value="Campinense">Campinense</option>
                      <option value="Caxias do Sul">Caxias do Sul</option>
                      <option value="Ceará">Ceará</option>
                      <option value="Chapecoense">Chapecoense</option>
                      <option value="Clube do Remo">Clube do Remo</option>
                      <option value="Corinthians">Corinthians</option>
                      <option value="Coritiba">Coritiba</option>
                      <option value="CRAC">CRAC</option>
                      <option value="CRB Alagoas">CRB Alagoas</option>
                      <option value="Criciúma">Criciúma</option>
                      <option value="Cruzeiro">Cruzeiro</option>
                      <option value="CSA">CSA</option>
                      <option value="Cuiabá">Cuiabá</option>
                      <option value="Desportiva Ferroviária ES">
                        Desportiva Ferroviária ES
                      </option>
                      <option value="Ferroviária">Ferroviária</option>
                      <option value="Ferroviária de Araraquara">
                        Ferroviária de Araraquara
                      </option>
                      <option value="Ferroviário CE">Ferroviário CE</option>
                      <option value="Figueirense">Figueirense</option>
                      <option value="Flamengo">Flamengo</option>
                      <option value="Flamengo Piauí">Flamengo Piauí</option>
                      <option value="Fluminense">Fluminense</option>
                      <option value="Fluminense de Feira">
                        Fluminense de Feira
                      </option>
                      <option value="Fortaleza">Fortaleza</option>
                      <option value="Gama">Gama</option>
                      <option value="Goiás">Goiás</option>
                      <option value="Goytacaz">Goytacaz</option>
                      <option value="Grêmio">Grêmio</option>
                      <option value="Guarani">Guarani</option>
                      <option value="Íbis">Íbis</option>
                      <option value="Internacional">Internacional</option>
                      <option value="Itabaiana">Itabaiana</option>
                      <option value="Ituano">Ituano</option>
                      <option value="Joinville">Joinville</option>
                      <option value="Juventude">Juventude</option>
                      <option value="Juventus">Juventus</option>
                      <option value="Linense">Linense</option>
                      <option value="Londrina EC">Londrina EC</option>
                      <option value="Luverdense">Luverdense</option>
                      <option value="Madureira">Madureira</option>
                      <option value="Marília">Marília</option>
                      <option value="Maringá">Maringá</option>
                      <option value="Metropolitano">Metropolitano</option>
                      <option value="Mirassol">Mirassol</option>
                      <option value="Mogi Mirim">Mogi Mirim</option>
                      <option value="Moto Club">Moto Club</option>
                      <option value="Náutico">Náutico</option>
                      <option value="Noroeste de Bauru">
                        Noroeste de Bauru
                      </option>
                      <option value="Novo Hamburgo">Novo Hamburgo</option>
                      <option value="Oeste Barueri">Oeste Barueri</option>
                      <option value="Operário Ferroviário">
                        Operário Ferroviário
                      </option>
                      <option value="Palmeiras">Palmeiras</option>
                      <option value="Paraná Clube">Paraná Clube</option>
                      <option value="Parnahyba">Parnahyba</option>
                      <option value="Paysandu">Paysandu</option>
                      <option value="Pelotas">Pelotas</option>
                      <option value="Penapolense">Penapolense</option>
                      <option value="Ponte Preta">Ponte Preta</option>
                      <option value="Portuguesa">Portuguesa</option>
                      <option value="Rio Branco ES">Rio Branco ES</option>
                      <option value="River Piauí">River Piauí</option>
                      <option value="Sampaio Corrêa">Sampaio Corrêa</option>
                      <option value="Santa Cruz">Santa Cruz</option>
                      <option value="Santo André">Santo André</option>
                      <option value="Santos">Santos</option>
                      <option value="São Bento">São Bento</option>
                      <option value="São Bernardo">São Bernardo</option>
                      <option value="São Caetano">São Caetano</option>
                      <option value="São Paulo">São Paulo</option>
                      <option value="Sergipe">Sergipe</option>
                      <option value="Sport Recife">Sport Recife</option>
                      <option value="Vasco da Gama">Vasco da Gama</option>
                      <option value="Vila Nova GO">Vila Nova GO</option>
                      <option value="Vila Nova MG">Vila Nova MG</option>
                      <option value="Vitória BA">Vitória BA</option>
                      <option value="Vitória ES">Vitória ES</option>
                      <option value="Volta Redonda">Volta Redonda</option>
                      <option value="XV de Piracicaba">XV de Piracicaba</option>
                    </select>
                  </div>
                  <div class="col-md-2 d-flex justify-content-center">
                    <div class="forms-input__send col-12 col-md-5">
                        <button className="btn-envio">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 16 16" fill="none">
                            <path d="M0.0927734 6.79C0.0927734 5.54 0.370898 4.39313 0.927148 3.34938C1.4834 2.30563 2.23027 1.44 3.16777 0.752502L4.04902 1.9525C3.29902 2.5025 2.70215 3.19625 2.2584 4.03375C1.81465 4.87125 1.59277 5.79 1.59277 6.79H0.0927734ZM13.5928 6.79C13.5928 5.79 13.3709 4.87125 12.9271 4.03375C12.4834 3.19625 11.8865 2.5025 11.1365 1.9525L12.0178 0.752502C12.9553 1.44 13.7021 2.30563 14.2584 3.34938C14.8146 4.39313 15.0928 5.54 15.0928 6.79H13.5928ZM1.59277 13.54V12.04H3.09277V6.79C3.09277 5.7525 3.40527 4.83063 4.03027 4.02438C4.65527 3.21813 5.46777 2.69 6.46777 2.44V1.915C6.46777 1.6025 6.57715 1.33688 6.7959 1.11813C7.01465 0.899377 7.28027 0.790002 7.59277 0.790002C7.90527 0.790002 8.1709 0.899377 8.38965 1.11813C8.6084 1.33688 8.71777 1.6025 8.71777 1.915V2.44C9.71777 2.69 10.5303 3.21813 11.1553 4.02438C11.7803 4.83063 12.0928 5.7525 12.0928 6.79V12.04H13.5928V13.54H1.59277ZM7.59277 15.79C7.18027 15.79 6.82715 15.6431 6.5334 15.3494C6.23965 15.0556 6.09277 14.7025 6.09277 14.29H9.09277C9.09277 14.7025 8.9459 15.0556 8.65215 15.3494C8.3584 15.6431 8.00527 15.79 7.59277 15.79ZM4.59277 12.04H10.5928V6.79C10.5928 5.965 10.299 5.25875 9.71152 4.67125C9.12402 4.08375 8.41777 3.79 7.59277 3.79C6.76777 3.79 6.06152 4.08375 5.47402 4.67125C4.88652 5.25875 4.59277 5.965 4.59277 6.79V12.04Z" fill="#0F0F0F"></path>
                          </svg>&nbsp;
                          Inscreva-me
                        </button>  
                    </div>
                  </div>
                  <div className="msg-resp"></div>
                </div>
              </form>
            </BoxForms>
          </div>
        </div>
      </section>
    );
  }
}
export default Forms;
