import bannerNacionalCyber from '../img/banner/nacionais-cyber.jpeg';
import bannerInternacionalCyber from '../img/banner/internacionais-cyber.jpeg';
import bannerSelecao from '../img/banner/selecoes-cyber.jpeg';
import bannerCalcados from '../img/banner/calcados-cyber.jpeg';
import { ImgBanner } from "./banner";

function BannersCyber() {
  /* const bannerinternacionalCyber = 'https://blackfriday.futfanatics.com.br/img/banner/bannerscyber/internacional.png';
  const bannerNacionalCyber = 'https://blackfriday.futfanatics.com.br/img/banner/bannerscyber/nacional.png';
  const bannerSelecao = 'https://blackfriday.futfanatics.com.br/img/banner/bannerscyber/selecao.png';
  const bannerlicenciados = 'https://blackfriday.futfanatics.com.br/img/banner/bannerscyber/licenciados.png'; */
  return (
    <>
      <div className="box-banner col-md-3">
      <a href='https://www.futfanatics.com.br/clubes-brasileiros
'>
          <ImgBanner src={bannerNacionalCyber}></ImgBanner>
        </a>
      </div>
      <div className="box-banner col-md-3">
      <a href='https://www.futfanatics.com.br/clubes-internacionais'>
          <ImgBanner src={bannerInternacionalCyber}></ImgBanner>
        </a>
      </div>
      <div className="box-banner col-md-3">
      <a href='https://www.futfanatics.com.br/selecoes'>
          <ImgBanner src={bannerSelecao}></ImgBanner>
        </a>
      </div>
      <div className="box-banner col-md-3">
      <a href=' https://www.futfanatics.com.br/calcados'>
          <ImgBanner src={bannerCalcados}></ImgBanner>
        </a>
      </div>
    </>
  );
}
export default BannersCyber;
