import styled from 'styled-components';

export const BoxTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const BoxMarca = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`
export const BoxApp = styled.div`
    display: flex;
    /* background-color: #161616; */
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;

    @media(max-width:478px){
        border-radius:10px;
    }
`

export const BoxInstall = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 1rem;
    padding: 1rem;
    border: 2px solid #fff;
    border-radius: 4px;

    &:first-child{
        margin-left: 0px;
    }

    &.cyber{
        border: 2px solid #fff;
    }
`
export const BoxDesconto = styled.div`
    width: 50%;
    padding: 0px 15px;

    img{
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
    }
`

export const BoxForms = styled.div`
    background-color: #232323;
    margin: 2rem auto;
    padding: 30px 40px;

    @media (min-width: 576px){
        border-radius: 20px;
    }
`