import { ImgBanner } from "./banner";
import bannerOakley from '../img/banner/banner-oakley.jpg';
import bannerOakleyMob from '../img/banner/banner-oakley-mob.jpg';
import bannerBraziline from '../img/banner/banner-braziline.jpg';
import bannerBrazilineMob from '../img/banner/banner-braziline-mob.jpg';
import bannerCocaCola from '../img/banner/banner-coca-cola.jpg';
import bannerCocaColaMob from '../img/banner/banner-coca-cola-mob.jpg';
import bannerPuma from '../img/banner/banner-puma.jpg';
import bannerPumaMob from '../img/banner/banner-puma-mob.jpg';

function BannersBlack() {
  //const bannerInternacional = 'https://blackfriday.futfanatics.com.br/img/banner/bannersblack/internacional.png';
  //const bannerNacional = 'https://blackfriday.futfanatics.com.br/img/banner/bannersblack/nacional.png';
  //const bannerSelecao = 'https://blackfriday.futfanatics.com.br/img/banner/bannersblack/selecao.png';
  //const bannerlicenciados = 'https://blackfriday.futfanatics.com.br/img/banner/bannersblack/licenciados.png';
  return (
    <>
      <div className="box-banner col-md-3">
        <a href='https://www.futfanatics.com.br/loja/busca.php?palavra_busca=oakley'>
          <ImgBanner src={window.innerWidth >= 768 ? bannerOakley : bannerOakleyMob}></ImgBanner>
        </a>
      </div>
      <div className="box-banner col-md-3">
        <a href='https://www.futfanatics.com.br/clubes-brasileiros?loja=311840&categoria=11&marca=marca_braziline&range='>
          <ImgBanner src={window.innerWidth >= 768 ? bannerBraziline : bannerBrazilineMob}></ImgBanner>
        </a>
      </div>
      <div className="box-banner col-md-3">
        <a href='https://www.futfanatics.com.br/loja/busca.php?palavra_busca=coca-cola'>
          <ImgBanner src={window.innerWidth >= 768 ? bannerCocaCola : bannerCocaColaMob}></ImgBanner>
        </a>
      </div>
      <div className="box-banner col-md-3">
        <a href='https://www.futfanatics.com.br/loja/busca.php?palavra_busca=puma'>
          <ImgBanner src={window.innerWidth >= 768 ? bannerPuma : bannerPumaMob}></ImgBanner>
        </a>
      </div>
    </>
  );
}
export default BannersBlack;
