import icons from "./svgmarcas.js"
import "./../assets/css/style.css";
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { TitleMarca } from "./Text.js";
import { NameMarca } from "./Text.js";
import { StyledButton } from "./Button.js";
import { BoxTitle } from "./box.js";
import { BoxMarca } from "./box.js";
import { Section } from "./section.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function MarcasCyber() {
  const marcasSlick = {
    dots: true,
    arrows: true,
    speed: 500,
    slidesToShow: 14,
    slidesToScroll: 4,
    responsive: [
      { breakpoint: 480, settings: { slidesToShow: 3.2, slidesToScroll: 3 } }, 
      { breakpoint: 767, settings: { slidesToShow: 4.2, slidesToScroll: 4 } }, 
      { breakpoint: 992, settings: { slidesToShow: 6.2, slidesToScroll: 6 } }, 
      { breakpoint: 1024, settings: { slidesToShow: 8, slidesToScroll: 8 } }, 
      { breakpoint: 1400, settings: { slidesToShow: 10, slidesToScroll: 10 } }
    ],
  };
  return (
    <Slider {...marcasSlick}>
      <div className="item" >
        <BoxMarca>
          <a className="img-marca icon-puma" href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=puma&order=0"></a>
        </BoxMarca>
      </div>
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-braziline" href="https://www.futfanatics.com.br/clubes-brasileiros?loja=311840&categoria=11&marca=marca_braziline&range="></a>
        </BoxMarca>
      </div>
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-coca-cola" href="https://www.futfanatics.com.br/loja/catalogo.php?loja=311840&categoria=1&marca=marca_coca-cola"></a>
        </BoxMarca>
      </div>
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-oakley" href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=oakley"></a>
        </BoxMarca>
      </div>
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-kappa" href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=kappa"></a>
        </BoxMarca>
      </div>
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-nb" href="https://www.futfanatics.com.br/treino?loja=311840&categoria=2&marca=marca_new-balance&range="></a>
        </BoxMarca>
      </div>
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-fila" href="https://www.futfanatics.com.br/treino?loja=311840&categoria=2&marca=marca_fila&range="></a>
        </BoxMarca>
      </div>
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-umbro" href="https://www.futfanatics.com.br/loja/catalogo.php?loja=311840&categoria=1051&marca=marca_umbro"></a>
        </BoxMarca>
      </div>
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-nicoboco" href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=nicoboco"></a>
        </BoxMarca>
      </div>      
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-olympikus" href="https://www.futfanatics.com.br/loja/catalogo.php?loja=311840&categoria=2&marca=marca_olympikus"></a>
        </BoxMarca>
      </div>
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-redley" href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=redley"></a>
        </BoxMarca>
      </div>
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-kenner" href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=kenner"></a>
        </BoxMarca>
      </div>
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-hurley" href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=hurley"></a>
        </BoxMarca>
      </div>
      <div className="item">
        <BoxMarca>
          <a className="img-marca icon-hang-loose" href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=hang-loose"></a>
        </BoxMarca>
      </div>
      
      {/* <div className="item">
        <BoxMarca>
          <a href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=reebok">
           <img
            src={icons.item25}
            className="img-marca lozad"
            alt="categoria internacional"
          /> 
          </a>
          <NameMarca>Reebok</NameMarca>
        </BoxMarca>
      </div> */}      
    </Slider>
  );
}

export default MarcasCyber;
