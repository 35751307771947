import React, { useState, useEffect } from 'react';
import './../assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import BannerDesk from "./../img/banners-bottom/banner-bottom-desk-black-2024.png";
import BannerMob from "./../img/banners-bottom/banner-bottom-mob-black-2024.png";
import BannerBottom1 from './../img/banners-bottom/linha-casual.jpg';
import BannerBottom2 from './../img/banners-bottom/camisas-oficiais.jpg';
import BannerBottom3 from './../img/banners-bottom/chuteiras.jpg';
import BannerBottom4 from './../img/banners-bottom/tenis-para-corrida.jpg';

function BannerBottomBlack() {
    const [BannerFull, setBannerFull] = useState(BannerDesk);
    const [BannerFullMob, setBannerFullMob] = useState(BannerMob);
  
    const bannersbottom = {
      dots: true,
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      centerMode: true,
      responsive: [
        { breakpoint: 600,  settings: { slidesToShow: 1, slidesToScroll: 1 } },
        { breakpoint: 767,  settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 1 } }
      ],
    };
  
    /* const BannerBottom1 = 'https://blackfriday.futfanatics.com.br/img/banner/bannersblack/trio-bottom_1.png';
    const BannerBottom2 = 'https://blackfriday.futfanatics.com.br/img/banner/bannersblack/trio-bottom_2.png';
    const BannerBottom3 = 'https://blackfriday.futfanatics.com.br/img/banner/bannersblack/trio-bottom_3.png'; */
  
    useEffect(() => {
      const dataCondicao = new Date('2024-12-01T00:00:00');
      const dataAtual = new Date();
  
      if (
        dataAtual.getTime() >= dataCondicao.getTime()
      ) {
        setBannerFull('https://blackfriday.futfanatics.com.br/img/banner/bannersblack/banner-middle-prorrogado.png');
        setBannerFullMob('https://blackfriday.futfanatics.com.br/img/banner/bannersblack/banner-middle_mobile-prorrogado.png');
      }
    }, []); 
  
  return (
    <>
      <div className='box-banner-full'>
        <a href="https://www.futfanatics.com.br/esportes?order=0">
          <img className='banner-full d-md-block d-none' src={BannerFull} alt="BannerFull" />
          <img className='banner-full d-md-none' src={BannerFullMob} alt="BannerFullMob" />
        </a>        
      </div>
      <div className='container mt-3'>
        <Slider {...bannersbottom} className='slick-banners_bottom'>
          <div className='item'>
            <a href='https://www.futfanatics.com.br/casual'>
              <img className='bannersBottom' src={BannerBottom1} alt="BannerBottom1" />
            </a>
          </div>
          <div className='item'>
            <a href='https://www.futfanatics.com.br/loja/busca.php?loja=311840&variacao=camisa-oficial_sim'>
              <img className='bannersBottom' src={BannerBottom2} alt="BannerBottom2" />
            </a>
          </div>
          <div className='item'>
            <a href='https://www.futfanatics.com.br/futebol/chuteiras?order=1'>
              <img className='bannersBottom' src={BannerBottom3} alt="BannerBottom3" />
            </a>
          </div>
          <div className='item'>
            <a href='https://www.futfanatics.com.br/calcados/corrida/?order=4'>
              <img className='bannersBottom' src={BannerBottom4} alt="BannerBottom4" />
            </a>
          </div>
        </Slider>
      </div>
    </>
  );
}

export default BannerBottomBlack;
