import React, {Component } from 'react';
import $ from 'jquery';

import Menu_Desk from "./Menu/Menu_Desk";

import logo from "../../img/icon/futfanatics.svg";
import iconSearch from "../../img/icon/search.svg";
import menu from "../../img/icon/hamburguer.svg";
import  { useState, useEffect } from 'react';

import '../../assets/css/style.css';
import Menu_Mobile from './Menu/Menu_Mobile';



class Header extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            logoImage: "https://blackfriday.futfanatics.com.br/img/icon/futfanatics.svg", 
            CartImage:"https://blackfriday.futfanatics.com.br/img/icon/iconcart.svg",
            InternacionalImage:"https://blackfriday.futfanatics.com.br/img/icon/internacional.svg",
            UserImage:"https://blackfriday.futfanatics.com.br/img/icon/iconuser.svg",
        };
    }
    
    componentDidMount = () => {
        this.checkLogoDate();
            
        function getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = document.cookie;
            var ca = decodedCookie.split(';');

            for (var i = 0; i < ca.length; i++) {
                var c = ca[i].trim();
                if (c.indexOf(name) == 0) {
                    return decodeURIComponent(c.substring(name.length, c.length));
                }
            }
            return "";
        }

        $.fn.cart = function () {
            var self = this,
            cartSession = getCookie('cartSession');

            if(cartSession.length == 0){
                cartSession = getCookie('cartSessionTray');
            }

            if(cartSession.length == 0){
                cartSession = getCookie('impulse_minicart_session');
            }

            self.add = function (productId, quantity, variantId, callback) {
                var self = this;

                $.ajax({
                    method: "POST",
                    url: "https://www.futfanatics.com.br/web_api/cart/",
                    contentType: "application/json; charset=utf-8",
                    crossDomain: true,
                    data: '{"Cart":{"session_id":"' + cartSession + '","product_id":"' + productId + '","quantity":"' + quantity + '","variant_id":"' + variantId + '"}}'
                }).done(function (response, textStatus, jqXHR) {

                    $('.cart-note').removeClass('d-none');

                    setTimeout(function () {
                        $('.cart-note').addClass('d-none');
                    }, 5000);

                    self.load();

                    if (callback && typeof callback == "function") {
                        callback(response);
                    }
                }).fail(function (jqXHR, status, errorThrown) {
                    var response = $.parseJSON(jqXHR.responseText);
                    console.error("[cart.js] - add error: ", response);
                    if (callback && typeof callback == "function")
                        callback(null);
                });
            };
            self.load = function () {
                $.ajax({
                    method: "GET",
                    crossDomain: true,
                    url: "https://www.futfanatics.com.br/web_api/cart/" + cartSession
                }).done(function (response, textStatus, jqXHR) {
                    var qtde_itens = 0;

                    response.forEach(function (item) {
                        var cart = item.Cart;

                        qtde_itens += parseInt(cart.quantity);
                    });

                    changeQty(qtde_itens);
                }).fail(function (jqXHR, status, errorThrown) {
                    var response = $.parseJSON(jqXHR.responseText);
                    if (response.name = "Nenhum registro encontrado") {
                        changeQty(0);
                    } else {
                        console.error("[cart.js] - load error: ", response);
                    }
                });
            };
            function changeQty(qty) {
                var cartQty = self.find(".qnty");
                console.log()
                cartQty.text(qty);
                if (qty > 0) {
                    cartQty.addClass("has-items");
                } else {
                    cartQty.removeClass("has-items");
                }
            }
            return self;
        };

        setTimeout(function () {
            window.Cart = $(".c-cart-header").cart();
            window.Cart.load();

            setSkin();

            console.log("[cart.js] - init");
        }, 200);

        var lastScrollTop = 0;

        $(window).scroll(function(event){
            var st = $(this).scrollTop();
            var hgHeader = $('header').height();
            var hgHeaderNav = $('header .header-nav').height();
            var hgBannerTop = $('body > .banner-top-site').height();
            var hgInfoDanger = $('body > .infobox.danger').height();

            if (!isMobile()) {
                if (st > (getNotNaN(hgHeader) - getNotNaN(hgHeaderNav)  + getNotNaN(hgBannerTop) + getNotNaN(hgInfoDanger) ) ) {
                    $('header .header-nav').css({'position': 'absolute', 'width': '100%', 'z-index': '-1'});
                    $('.custom-page.page-black').css({'margin-top': '53px'});

                    if (st > lastScrollTop) {
                        $('header .header-nav').css({'top': '0'});
                    } else {
                        $('header .header-nav').css({'top': '100%'});
                    }
                } else {
                    $('header .header-nav').css({'position': 'relative', 'width': 'auto', 'z-index': '0'});
                    $('.custom-page.page-black').css({'margin-top': '0'});
                }
            } else {
                if (st > ((getNotNaN(hgHeader) + getNotNaN(hgBannerTop)  + getNotNaN(hgInfoDanger) ) ) ) {
                    $('.header-infos .busca').css({'position': 'absolute', 'width': '100%'});
                    $('.custom-page.page-black').css({'margin-top': '43px'});

                    if (st > lastScrollTop) {
                        $('.header-infos .busca').stop(true).slideUp(0);
                        $('.links-usuario .search').removeClass('invisible');
                    } else {
                        $('.header-infos .busca').stop(true).slideDown(0);
                        $('.links-usuario .search').addClass('invisible');
                    }
                } else {
                    $('.header-infos .busca').css({'position': 'relative', 'width': 'auto'});
                    $('.custom-page.page-black').css({'margin-top': '0'});
                }
            }
            lastScrollTop = st;
        });

        $('.header-infos .links-usuario .search a').on('click', function() {
            $('.header-infos .busca').stop(true).slideToggle();
            return false;
        });

        $('header .menu-mob-open').on('click', function() {
            $('body').addClass('menu-open');
            $('.header-nav').css('left', '0');
        });

        $('header .menu-mob-close').on('click', function() {
            $('body').removeClass('menu-open');
            $('.header-nav').css('left', '-100%');
        });

        if (isMobile()) {
            $('.header-nav a').on('click', function(event) {
                var href = $(this).attr('href');
                if (href == "") {
                    $(this).parents('ul').scrollTop(0);
                    $(this).siblings('ul').show();
                    $(this).parents('ul').css('overflow-y', 'hidden');
                    $('.header-nav .nav-top a.link-user').removeClass('invisible');
                    // $('.header-nav .nav-top a.link-inter').addClass('d-none');
                    return false;
                }
            });

            $('.header-nav li.back').on('click', function(event) {
                $(this).parent().hide().parents('ul').css('overflow-y', 'auto');
                if ($(this).parents('ul').length < 3) {
                    $('.header-nav .nav-top a.link-user').addClass('invisible');
                    // $('.header-nav .nav-top a.link-inter').removeClass('d-none');
                }
            });
        }

        $(document).on('click', function(event) {
            if (!$(event.target).is('.cart-header *') && !$('.cart-header .menu-cart').hasClass('d-none')) {
                $('.cart-header > a').trigger('click');
            }

            if (!$(event.target).is('.central *') && !$('.central .menu-central').hasClass('d-none')) {
                $('.central > a').trigger('click');
            }
        });

        $(document).scroll(function() {
            if (!$('.cart-header .menu-cart').hasClass('d-none')) {
                $('.cart-header > a').trigger('click');
            }

            if (!$('.central .menu-central').hasClass('d-none')) {
                $('.central > a').trigger('click');
            }
        });

        var ff_app_cookie = 'ff_app';
        var ff_app_cookie_value = 'Banner App';
        var ff_app_cookie_domain = "futfanatics.com.br";

        function getNotNaN($dom) {
            if (!isNaN($dom))
                return $dom;
            else
                return 0;
        }

        function setSkin() {
            setTimeout(() => {
              document.body.style.backgroundColor = "#000";
              const divs = Array.from(document.querySelectorAll(".chaordic"));
              divs.map((div) => {
                const hasChildren = div.childNodes;
                const widget =
                  div &&
                  div.childNodes &&
                  div.childNodes[0] &&
                  div.childNodes[0].contentDocument &&
                  div.childNodes[0].contentDocument.querySelector("#widget");
                if (widget) {
                  widget.classList.add("skin-bf");
                }
              });
            }, 10);
          }

        function isMobile() {
            if (window.innerWidth > 991) {
                return false;
            }
            return true;
        }

        function setCookie(cname, cvalue, exdays, domain) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires=" + d.toGMTString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=." + domain;
        }

        function getCookie(cname) {
            var name = cname + "=";
            // var decodedCookie = decodeURIComponent(document.cookie);
            var decodedCookie = document.cookie;
            var ca = decodedCookie.split(';');

            // var filterCookies = cookies.filter(function(e) {
            // 	return e.trim().indexOf(name) == 0;
            // });

            // return filterCookies[0] ? decodeURIComponent(filterCookies[0].substring(name.length, c.length)) : "";

            for(var i = 0; i < ca.length; i++) {
                var c = ca[i].trim();
                // while (c.charAt(0) == ' ') {
                // 	c = c.substring(1);
                // }
                if (c.indexOf(name) == 0) {
                    return decodeURIComponent(c.substring(name.length, c.length));
                }
            }
            return "";
        }

        function checkCookie(cname) {
            var cookie = getCookie(cname);
            if (cookie != "") {
                return cookie;
            } else {
                return "";
            }
        }
    }

    checkLogoDate = () => {
        const currentDate = new Date();
        const logoChangeDate = new Date('2024-12-01T00:00:00'); 
        if (currentDate >= logoChangeDate) {
            
            this.setState({
                logoImage:"https://blackfriday.futfanatics.com.br/img/icon/futfanaticscyber.svg", 
                CartImage:"https://blackfriday.futfanatics.com.br/img/icon/icon-cartcyber.svg",
                InternacionalImage:"https://blackfriday.futfanatics.com.br/img/icon/internacionalcyber.svg",
                UserImage:"https://blackfriday.futfanatics.com.br/img/icon/iconusercyber.svg",
            });
            $('.header-top').addClass('cyber');
        }
    }
    
    render(){
        return (
            <header>
                <div className="header-top">
                    <div className="header-infos">
                        <div className="container-fluid">
                            <div className="row py-1 py-md-3 align-items-center">
                                <div className="col-4 d-lg-none">
                                    <button className="menu-mob-open icon-menu"><img className="icon-menu d-md-none" src={menu}/></button>
                                </div>
                                <div className="col-4 col-lg-1 d-flex justify-content-center justify-content-lg-start">
                                    <a href="https://futfanatics.com.br" className="logo">
                                        <img className="icon-logo-fut" src={logo}/>
                                    </a>
                                </div>
                                <div className="col-12 order-last col-lg-8 order-lg-0 position-relative">
                                    <div className="busca">
                                        <form action="https://busca.futfanatics.com.br/busca" className="d-flex align-items-center">
                                            <input type="hidden" name="loja" value="{{ store.id }}" />

                                            <button type="submit" className="order-last order-lg-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" class="bi bi-search" viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                            </svg>
                                            </button>

                                            <input id="search-field" type="text" name="q" placeholder="O que você procura?" />
                                        </form>
                                    </div>
                                </div>
                                <div className="col-4 col-lg-3 d-flex justify-content-between links-usuario">
                                    <div className="link-inter align-self-center">
                                        <a href="https://www.futfanatics.net/?utm_source=futfanatics-nacional&utm_medium=referral&utm_campaign=selo-topo" target="_blank" className="d-none d-lg-flex align-items-center">
                                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.9997 21.0001H9.99973V17.4081L7.64273 15.0001H5.23474C4.9255 14.9999 4.62049 14.928 4.34372 14.7901L2.55273 13.8901L3.44672 12.1011L5.23474 12.9951H7.64273C7.90897 12.9952 8.17252 13.0484 8.41803 13.1514C8.66354 13.2544 8.88608 13.4052 9.07272 13.5951L11.4287 16.0021C11.7963 16.3751 12.0015 16.8784 11.9997 17.4021V21.0001Z"/>
                                            <path d="M3.44672 11.895L2.55273 10.106L6.11472 8.32397L7.01474 3.80396L8.97574 4.19696L8.07571 8.71695C8.01728 9.01656 7.89066 9.29872 7.70563 9.5415C7.52059 9.78429 7.28213 9.9812 7.00873 10.117L3.44672 11.895Z" />
                                            <path d="M17.2932 19.7071L15.4602 17.8741C15.2396 17.6547 15.0739 17.3864 14.9762 17.0911L14.1602 14.6431L12.0802 13.256C11.7146 13.0124 11.4394 12.6551 11.2972 12.2393C11.1549 11.8236 11.1535 11.3726 11.2932 10.9561L12.8932 6.15607C12.9913 5.86188 13.1565 5.59452 13.3757 5.37515C13.5949 5.15578 13.8621 4.99041 14.1562 4.89206L16.6832 4.05005L17.3152 5.95004L14.7892 6.79205L13.1892 11.592L15.2692 12.9781C15.6394 13.2259 15.9169 13.5896 16.0582 14.0121L16.8732 16.4601L18.7072 18.2931L17.2932 19.7071Z" />
                                            <path d="M12 4C13.5823 4 15.129 4.46918 16.4446 5.34824C17.7602 6.22729 18.7856 7.47673 19.3911 8.93854C19.9966 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C16.5381 18.7757 15.1126 19.5376 13.5607 19.8463C12.0089 20.155 10.4004 19.9965 8.93855 19.391C7.47674 18.7855 6.2273 17.7601 5.34825 16.4445C4.4692 15.129 4.00001 13.5823 4.00001 12C4.00239 9.879 4.846 7.84554 6.34578 6.34576C7.84555 4.84599 9.87901 4.00238 12 4ZM12 2C10.0222 2 8.08881 2.58649 6.44432 3.6853C4.79983 4.78412 3.51809 6.3459 2.76121 8.17316C2.00434 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6726 4.92894 19.0711C6.32746 20.4696 8.10931 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8269 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34784 20.9465 6.80429 19.0711 4.92892C17.1957 3.05356 14.6522 2 12 2Z"/>
                                        </svg>
                                        International Orders</a>
                                    </div>
                                    <div className="c-central">
                                        <a href="https://futfanatics.com.br/central-do-cliente" className="d-none d-md-block">
                                        <svg width="16" height="20" viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 12C6.67441 11.9984 5.40355 11.4711 4.46622 10.5338C3.52888 9.59645 3.00159 8.32559 3 7V5C3 3.67392 3.52678 2.40213 4.46446 1.46445C5.40214 0.526765 6.67392 0 8 0C9.32608 0 10.5979 0.526765 11.5355 1.46445C12.4732 2.40213 13 3.67392 13 5V7C12.9984 8.32559 12.4711 9.59645 11.5338 10.5338C10.5964 11.4711 9.32559 11.9984 8 12ZM8 2C7.20435 2 6.44129 2.31605 5.87868 2.87866C5.31607 3.44127 5 4.20435 5 5V7C5 7.79565 5.31607 8.5587 5.87868 9.12131C6.44129 9.68392 7.20435 10 8 10C8.79565 10 9.55871 9.68392 10.1213 9.12131C10.6839 8.5587 11 7.79565 11 7V5C11 4.20435 10.6839 3.44127 10.1213 2.87866C9.55871 2.31605 8.79565 2 8 2Z" />
                                            <path d="M12 15C12.5304 15 13.0391 15.2107 13.4142 15.5858C13.7893 15.9609 14 16.4696 14 17V18H2V17C2 16.4696 2.21071 15.9609 2.58578 15.5858C2.96086 15.2107 3.46957 15 4 15H12ZM12 13H4C2.93913 13 1.92173 13.4214 1.17159 14.1716C0.42144 14.9217 0 15.9391 0 17V19C0 19.2652 0.105356 19.5196 0.292892 19.7071C0.480429 19.8946 0.734784 20 1 20H15C15.2652 20 15.5196 19.8946 15.7071 19.7071C15.8946 19.5196 16 19.2652 16 19V17C16 15.9391 15.5786 14.9217 14.8284 14.1716C14.0783 13.4214 13.0609 13 12 13Z" />
                                        </svg>
                                        </a>
                                    </div>
                                    <div className="search d-lg-none invisible">
                                        <a href=""><i className="icon-search"></i></a>
                                    </div>
                                    <div className="c-cart-header">
                                        <span className="qnty">0</span>
                                        <a href="https://futfanatics.com.br/loja/carrinho.php?loja=311840">
                                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 22C9.10457 22 10 21.1046 10 20C10 18.8954 9.10457 18 8 18C6.89543 18 6 18.8954 6 20C6 21.1046 6.89543 22 8 22Z" />
                                            <path d="M16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22Z" />
                                            <path d="M20.037 4.72797L18.245 11.002H7.72099L4.94899 2.68597C4.88264 2.48677 4.75528 2.31347 4.58495 2.1907C4.41461 2.06794 4.20996 2.0019 4 2.00195H2V4.00195H3.28L5.71399 11.302C5.09814 11.5924 4.59975 12.0842 4.30107 12.6961C4.00239 13.308 3.92128 14.0035 4.07114 14.6677C4.22099 15.3319 4.59285 15.9252 5.12534 16.3495C5.65782 16.7739 6.31909 17.0041 7 17.002H19V15.002H7C6.73478 15.002 6.48043 14.8966 6.29289 14.7091C6.10536 14.5215 6 14.2672 6 14.002C6 13.7367 6.10536 13.4824 6.29289 13.2949C6.48043 13.1073 6.73478 13.002 7 13.002H19C19.2175 13.002 19.4291 12.9312 19.6027 12.8002C19.7763 12.6692 19.9024 12.4851 19.962 12.276L21.962 5.27597L20.037 4.72797Z"/>
                                        </svg>
                                        </a>

                                        <div className="cart-note d-none">
                                            <h3>Carrinho Atualizado</h3>
                                            <a href="https://www.futfanatics.com.br/loja/carrinho.php?loja=311840">Finalizar compra</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-nav">
                        <Menu_Desk/>
                        <Menu_Mobile/>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header;