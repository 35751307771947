import "./../assets/css/style.css";
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImgBanner } from "./banner";
import { BoxTitle } from "./box";
import { Section } from "./section";
import { TitleMarca } from "./Text";

import Imagemteste from "../img/banner/destaque-banner.png";

function Destaqueblack() {
  const DestaqueSlick = {
    dots: true,
    arrows: true,
    speed: 500,
    infinite:true,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: true,
    responsive: [
      { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 1 } }
    ],
  };
  // const Aprrove = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/approve.png';
  // const Betel = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/betel.png';
  // const Braziline = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/braziline.png';
  // const Coca = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/coca-cola.png';
  // const colcci = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/colcci.png';
  // const Diversao = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/diversao.png';
  // const Fila = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/fila.png';
  // const Hang = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/hang loose.png';
  // const Hurley = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/hurley.png';
  // const Kenner = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/kenner.png';
  // const Mizuno= 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/mizuno.png';
  // const Nicoboco= 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/nicoboco.png';
  // const Oakley = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/oakley.png';
  // const Olympikus = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/olympikus.png';
  // const Oxn= 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/oxn.png';
  // const Puma = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/puma.png';
  // const Redley = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/redley.png';
  // const Retromania = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/retro-mania.png';
  // const SurfCenter = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/surf-center.png';
  // const Umbro = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/umbro.png';
  // const UnderArmour = 'https://blackfriday.futfanatics.com.br/img/destaque/destaque-black/under-armour.png';
  return (
    <Slider {...DestaqueSlick} className="slick-destaque">
    <div className="item">
      <a href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=asics">
        <ImgBanner src={require('../img/destaque/asics.jpg')} />
      </a>
    </div>
    <div className="item">
      <a href="https://www.futfanatics.com.br/treino?loja=311840&categoria=2&marca=marca_fila&range=">
        <ImgBanner src={require('../img/destaque/fila.jpg')} />
      </a>
    </div>
    <div className="item">
      <a href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=hang-loose">
        <ImgBanner src={require('../img/destaque/hangloose.jpg')} />
      </a>
    </div>
    <div className="item">
      <a href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=hurley">
        <ImgBanner src={require('../img/destaque/hurley.jpg')} />
      </a>
    </div>
    <div className="item">
      <a href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=kappa">
        <ImgBanner src={require('../img/destaque/kappa.jpg')} />
      </a>
    </div>
    <div className="item">
      <a href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=kenner">
        <ImgBanner src={require('../img/destaque/kenner.jpg')} />
      </a>
    </div>
    <div className="item">
      <a href="https://www.futfanatics.com.br/treino?loja=311840&categoria=2&marca=marca_new-balance&range=">
        <ImgBanner src={require('../img/destaque/newbalance.jpg')} />
      </a>
    </div>
    <div className="item">
      <a href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=nicoboco">
        <ImgBanner src={require('../img/destaque/nicoboco.jpg')} />
      </a>
    </div>
    <div className="item">
      <a href="https://www.futfanatics.com.br/loja/catalogo.php?loja=311840&categoria=2&marca=marca_olympikus">
        <ImgBanner src={require('../img/destaque/olympikus.jpg')} />
      </a>
    </div>
    <div className="item">
      <a href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=redley">
        <ImgBanner src={require('../img/destaque/redley.jpg')} />
      </a>
    </div>
    <div className="item">
    <a href="https://www.futfanatics.com.br/loja/busca.php?palavra_busca=reserva">
        <ImgBanner src={require('../img/destaque/reserva.jpg')} />
      </a>
    </div>
    <div className="item">
      <a href="https://www.futfanatics.com.br/loja/catalogo.php?loja=311840&categoria=1051&marca=marca_umbro">
        <ImgBanner src={require('../img/destaque/umbro.jpg')} />
      </a>
    </div>
  </Slider>
  );
}

export default Destaqueblack;
