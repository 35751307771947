import descontoCyber1 from '../img/descontos/desconto-cyber-1.jpeg'
import descontoCyber2 from '../img/descontos/desconto-cyber-2.jpeg'
import descontoCyber3 from '../img/descontos/desconto-cyber-3.jpeg'
import descontoCyber4 from '../img/descontos/desconto-cyber-4.jpeg'
import { BoxDesconto } from './box'

function DescontosCyber() {
  /* const Cyber10 = 'https://blackfriday.futfanatics.com.br/img/descontos/cyber-10.png';
  const Cyber20 = 'https://blackfriday.futfanatics.com.br/img/descontos/cyber-20.png';
  const Cyber30 = 'https://blackfriday.futfanatics.com.br/img/descontos/cyber-30.png';
  const Cyber40 = 'https://blackfriday.futfanatics.com.br/img/descontos/cyber-40.png'; */
  return(
    
    <>
    <div className="col-md-6 d-flex col-left">
      <BoxDesconto>
        <a href='https://www.futfanatics.com.br/loja/busca.php?loja=311840&range=6.00-31.00'>
          <img className="desconto-img" src={descontoCyber1} />
        </a>
      </BoxDesconto>
      <BoxDesconto>
        <a href='https://www.futfanatics.com.br/loja/busca.php/?loja=311840&range=6.00-61.00'>
          <img className="desconto-img" src={descontoCyber2} />
        </a>
      </BoxDesconto>
    </div>
    <div className="col-md-6 d-flex col-right">
      <BoxDesconto>
        <a href='https://www.futfanatics.com.br/loja/busca.php/?loja=311840&range=6.00-108.00'>
          <img className="desconto-img" src={descontoCyber3} />
        </a>
      </BoxDesconto>
      <BoxDesconto>
        <a href='https://www.futfanatics.com.br/loja/busca.php/?loja=311840&range=6.00-211.00&order=2'>
          <img className="desconto-img" src={descontoCyber4} />
        </a>
      </BoxDesconto>
    </div>
  </>
  )
  
}
export default DescontosCyber;
